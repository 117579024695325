import React, { useCallback, useEffect, useRef, useState } from "react";
import './background.scss';
import { getCurrentWeather } from "../../gateway/weather";

const Backgrund = () => {
  const time = useRef(getSunMoonPositionClassByHour());
  const [isLocationDenied, setIsLocationDenied] = useState();
  const [weatherData, setWeatherData] = useState({});
  const [isRainy, setIsRainy] = useState(false);

  const backgroundRef = useRef();
  const cloudiness = useRef(5);
  const temprature = useRef();
  const isRainAdded = useRef(false);

  const {
    clouds: { all = 100 } = {},
    main: { temp = 0 } = {},
    weather = [],
  } = weatherData;
  cloudiness.current = Math.floor(5 * (all / 100));
  temprature.current = temp ? Math.ceil(temp) : 'N/A';

  useEffect(() => {
    let isDrizzle = false;
    let isRain = false;
    let isThunderstorm = false;

    weather.forEach(entry => {
      if (entry.main === 'Rain') {
        isRain = true;
      }
      if (entry.main === 'Drizzle') {
        isDrizzle = true;
      }
      if (entry.main === 'Thunderstorm') {
        isThunderstorm = true;
      }
    });

    const addRain = isDrizzle || isRain || isThunderstorm;
    setIsRainy(addRain);

    if (!isRainAdded.current && addRain) {
      isRainAdded.current = true;
      let hrElement;
      let counter = isRain ? 100 : isDrizzle ? 20 : 0;

      for (let i = 0; i < counter; i++) {
        hrElement = document.createElement("HR");
        if (i === counter - 1 && isThunderstorm) {
          hrElement.className = "thunder";
        } else {
          hrElement.style.left = Math.floor(Math.random() * (window.innerWidth - 50)) + "px";
          hrElement.style.animationDuration = 0.2 + Math.random() * 0.3 + "s";
          hrElement.style.animationDelay = Math.random() * 5 + "s";
        }
        backgroundRef.current.appendChild(hrElement);
      }
    }
  }, [weather, weatherData])

  const positionCSS = {
    left: time.current.x + '%',
    top: time.current.y + '%',
  };

  const successFunction = useCallback((data) => {
    setIsLocationDenied(false);
    var lat = data.coords.latitude;
    var long = data.coords.longitude;

    getCurrentWeather(lat, long).then(res => {
      setWeatherData(res);
    }).catch(() => {
      console.error("Error fetching weather info");
    })
  }, []);

  const errorFunction = useCallback(() => {
    setIsLocationDenied(true);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    } else {
      alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
    }
  }, [errorFunction, successFunction]);

  useEffect(() => {
    let i = 0;
    const clouds = setInterval(() => {
      if (i < cloudiness.current) {
        const div = document.createElement('div');
        div.classList.add('cloud');
        div.classList.add(`_${i}`);
        document.getElementsByClassName('content')[0].appendChild(div);
        i++;
      } else {
        clearInterval(clouds);
      }
    }, 3000);
  }, []);

  return <div className={`content ${time.current.showMoon ? 'moonlight' : isRainy ? ' rainy' : ''}`}>
    {isLocationDenied && <div className="location-banner">Enable Location for realtime weather</div>}
    <div className="cloud _3"></div>
    {time.current.showMoon ? <div className="moon" style={positionCSS}>
      <div className="crator crator1"></div>
      <div className="crator crator2"></div>
      <div className="crator crator3"></div>
      <div className={`${temprature.current ? '' : 'loader'} temprature`}>{temprature.current}&deg;C</div>
    </div> :
      <div className="sun" style={positionCSS}>
        <div className={`${temprature.current ? '' : 'loader'} temprature`}>{temprature.current}&deg;C</div>
      </div>
    }
    <div ref={backgroundRef}></div>
  </div>
};

export default Backgrund;

const getSunMoonPositionClassByHour = () => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  const showMoon = currentHour > 18 || currentHour < 7;

  const position = showMoon ? getMoonPosition(currentHour) : Math.abs(currentHour - 7);

  const x = 4 + (7 * position);
  let y = 1 + (0.45 * position);

  if (showMoon) {
    if (position >= 0 && position < 5) {
      y = 6 + position;
    } else {
      y = 1 + (position - 6)
    }
  }

  return { showMoon, x, y }
};

const getMoonPosition = (hour) => {
  if (hour < 24 && hour > 18) {
    return hour - 19;
  } else {
    return 5 + hour;
  }
}
