import React, { useState } from "react";
import "./overlay-menu.scss";

const OverlayMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return <div className="overlay-menu">
    {/* <div className={isMenuVisible ? "common-properties movable-menu-common facebook-button visible" : "common-properties movable-menu-common facebook-button"} onClick={() => goToProfile('fb')}></div> */}
    <div className={isMenuVisible ? "common-properties movable-menu-common linkedin-button visible" : "common-properties movable-menu-common linkedin-button"} onClick={() => goToProfile('linkedin')}></div>
    <div className={isMenuVisible ? "common-properties movable-menu-common insta-button visible" : "common-properties movable-menu-common insta-button"} onClick={() => goToProfile('insta')}></div>
    <div className="common-properties main-button" onClick={toggleMenu}></div>
  </div>
};

export default OverlayMenu;

export const goToProfile = (platform) => {
  switch (platform) {
    case 'fb':
      window.open('https://www.facebook.com/ojas.pote', '__balnk');
      break;
    case 'linkedin':
      window.open('https://www.linkedin.com/in/ojas-pote/', '__balnk');
      break;
    case 'insta':
      window.open('https://www.instagram.com/ojas_pote/', '__balnk');
      break;
    default:
      break;
  }
};
