import React from "react";
import './aboutme.scss';

const todayDate = new Date();
const date = new Date("Tue Jan 17 2017 10:43:56 GMT+0530");
let yearDifference = todayDate.getFullYear() - date.getFullYear();
let monthDifference = todayDate.getMonth() - date.getMonth();

if (monthDifference < 0) {
  yearDifference -= 1;
  monthDifference = (12 - date.getMonth()) + todayDate.getMonth();
}

const AboutMe = () => {

  return <div className={'aboutme'}>
    <div className="section">
      <div className="title">About Me</div>
      <p>
        Hi, I am a software engineer from Mumbai <span className="heart"></span>, India <span className="country"></span>. With overall experience of {monthDifference > 0 ? `${yearDifference} years and ${monthDifference} month(s)` : yearDifference}. I have mainly
        worked in an e-Learning and AppSec domain. Currently working as a senior software engineer
        with Hurain Infotech LLC. Mumbai.
      </p>
      <p>
        I have completed my Bachelor of Engineering in 2016 from University of Mumbai.
      </p>
    </div>
  </div>
};

export default AboutMe;
