const EXPERIENCE = [{
    name: "Hurain Infotech LLP, Mumbai",
    duration: "Feb 2021 - Present",
    position: "Sr. Software Engineer",
    clients: [{
        name: "A major AppSec service provider USA",
        projects: [{
            name: "AppSec dashboard",
            responsibilities: "Responsible for new UI component development and bug fixes. Created few REST APIs to store user preferences. Created templates for PDF and email using velocity engine.",
            technologies: ["React", "SCSS", "JavaScript", "recharts", "antd", "Java", "Velocity Engine"]
        }]
    }, {
        name: "A major e-learning company USA",
        projects: [{
            name: "student dashboard application",
            responsibilities: "Responsible for new UI component/features development and bug fixes. Writing Unit tests and puppeteer and selenium automation tests.",
            technologies: ["React", "Redux", "Styled Components", "Jest/RTL", "Playwright", "Selenium"]
        }]
    }]
}, {
    name: "MITR Learning and Media Pvt. Ltd., Mumbai",
    duration: "Jan 2017 - Jan 2021",
    position: "GET(2017)/ Software Engineer(2018-2021)",
    clients: [{
        name: "A major e-learning company USA",
        projects: [{
            name: "teacher dashboard application",
            responsibilities: "Responsible for new UI component development and bug fixes. Writing Unit tests and puppeteer automation tests.",
            technologies: ["React", "Redux", "SCSS", "Jest", "Puppeteer"]
        }, {
            name: "e-reader application",
            responsibilities: "Responsible for new UI component development and bug fixes.",
            technologies: ["Angular"]
        }, {
            name: "interactive HTML5 canvas activities",
            responsibilities: "Responsible developing new activities using HTML5 canvas",
            technologies: ["JavaScript", "HTML canvas"]
        }]
    }, {
        name: "A major e-learning company USA",
        projects: [{
            name: "A combined dashboard for teachers and students",
            responsibilities: "Responsible for new UI component development and bug fixes.",
            technologies: ["React", "Redux", "SCSS"]
        }, {
            name: "An authoring platform for teachers",
            responsibilities: "Responsible for new UI component development and bug fixes.",
            technologies: ["React", "Redux", "CSS"]
        }, {
            name: "a student application",
            responsibilities: "Responsible UI development and bug fixes.",
            technologies: ["JQuery", "JavaScript", "CSS"]
        }, {
            name: "interactive HTML5 canvas activities",
            responsibilities: "Responsible developing new activities using HTML5 canvas",
            technologies: ["JavaScript", "CreateJS", "HTML canvas"]
        }]
    }],

}];

export default EXPERIENCE;