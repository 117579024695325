import Backgrund from './pages/Background/Background';
import Header from './pages/Header/Header';
import Home from './pages/Home/Home';
import OverlayMenu from './pages/OverlayMenu/OverlayMenu';

function App() {
  return (<>
    <Backgrund />
    <Home />
    <Header />
    <OverlayMenu />
  </>
  );
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
