import React from "react";
import './footer.scss';
import { goToProfile } from "../OverlayMenu/OverlayMenu";

const year = new Date().getFullYear();
const Footer = () => {

  return <div className={'footer'}>
      <div className="social-link m-b-s">
          <div className="p-l-s">Social Connect</div>
          <div className="social-icon facebook" onClick={() => goToProfile("fb")}></div>
          <div className="social-icon linkedin" onClick={() => goToProfile("linkedin")}></div>
          <div className="social-icon instagram" onClick={() => goToProfile("insta")}></div>
      </div>
      <div className="footer-text">Ojas Pote &#169;{year}</div>
  </div>
};

export default Footer;
