import React, { useRef } from "react";
import './home.scss';
import AboutMe from '../AboutMe/AboutMe';
import Experience from '../Experience/Experience';
import Footer from "../Footer/Footer";

const Home = () => {
  const message = useRef(getMessageByHour());

  return <div className={'home'}>
    <div className={'salutation-container'}>
      <div className={'salutation'}>
        <div>Welcome!</div>
        <div>{message.current} :)</div>
        <div className="arrowContainer">
          <div className="arrowHead"></div>
          <div className="arrowTail"></div>
        </div>
      </div>
    </div>
    <AboutMe />
    <Experience />
    <Footer />
  </div>
};

export default Home;

const getMessageByHour = () => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  if (currentHour >= 17 || currentHour < 3) {
    return `Good Evening.`;
  } else if (currentHour >= 12 && currentHour < 17) {
    return `Good Afternoon.`;
  } else {
    return `Good Morning.`;
  }
};
