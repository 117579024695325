import React from "react";
import './experience.scss';
import EXPERIENCE from '../../constants/experience';

const Experience = () => {
  return <div className={'experience'}>
    <div className="section">
      <div className="title">Experience</div>
      {EXPERIENCE.map((entry) => (
        <div className="company-container" key={entry.name}>
          <div className="company-name">{entry.name}</div>
          <div className="position-duration">
            <div className="position">{entry.position}</div>
            <div className="duration">{entry.duration}</div>
          </div>
          <div className="projects-list">
            <div className="i-am-bold m-t-m">Clients</div>
            <ol style={{ listStyleType: 'none', padding: '0px 10px' }}>
              {
                entry.clients.map((client, index) => (
                  <li className="client-company m-b-s" key={client.name + index}>
                    <div className="client-name">{client.name}</div>
                    <ul className="projects">
                      <div className="i-am-bold m-t-m m-b-s">Projects</div>
                      {
                        client.projects.map((project) => (
                          <li key={project.name} className="m-b-s">
                            <div className="project-name">{project.name}</div>
                            <div className="project-responsibilities"><b>Role:</b> {project.responsibilities}</div>
                            <div className="project-technologies"><b>Technologies/Libraries:</b> {project.technologies.join(', ')}</div>
                          </li>
                        ))
                      }
                    </ul>
                  </li>
                ))
              }
            </ol>
          </div>
        </div>
      ))}
    </div>
  </div>
};

export default Experience;
