import React from "react";
import './header.scss';
import ShortLogo from '../../assets/full.svg';

const Header = () => {
  return <div className="static-header">
      <div className="logo-holder">
          <img alt="logo" src={ShortLogo} />
      </div>
      <a href="mailto:ojp1994@gmail.com"><i class="fa fa-envelope" style={{ fontSize: 41, color: "black"}}></i></a>
  </div>
};

export default Header;
